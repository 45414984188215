html {
  background-color: black;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  height: 100%;
  bottom: 90px;
  font-family: "Gill Sans", sans-serif;
  /* font-family: "Cantarell", sans-serif; */
}

.agedescription {
  color: #e2b34b;
  font-size: 20pt;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0px 10px 200px 10px;
  margin-bottom: 0;
}

.main {
  position: relative;
  padding: 100px 0px 400px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 30px; */
}

.main::before {
  content: "";
  background-image: url("img/age-dark-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.4;
}

@media (max-width: 768px) {
  body {
    height: 100vh;
  }
}

/* NEW GENERIC EVENTCARDS */
.ticket-link {
  color: #e2b34b;
}

/* EVENTCARD GLOBAL */

.ticket-button {
  background-color: #060606;
  color: #b95b0a;
  border: none;
  width: 100%;
  /* opacity: 0.8; */
}

.ticket-button:hover {
  color: #b1c479;
  background-color: rgba(226, 179, 75, 0.4);
}
.artist-website-button {
  background-color: #060606;
  color: #b95b0a;
  border: none;
  width: 100%;
  /* opacity: 0.8; */
  font-size: 15pt;
}

.artist-website-button:hover {
  color: #b1c479;
  background-color: rgba(226, 179, 75, 0.4);
}
.basement-artist-website-button {
  background-color: #060606;
  color: #e2b34b;
  border: none;
  font-size: 15pt;
  width: 100%;
}
.basement-artist-website-button:hover {
  color: #b1c479;
  background-color: rgba(226, 179, 75, 0.4);
}
.event-card {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0px 0px 0px 0px;
  width: 100%;
}

/* BOY GOLDEN CARD */

h3 {
  position: relative;
  color: #b1c479;
  line-height: 0.9;
  text-align: left;
}

h1 {
  color: #b1c479;
  font-variant: small-caps;
}

p {
  position: relative;
  color: #e2b34b;
  line-height: 0.9;
  text-align: center;
  font-size: 15pt;
}

.event-card-title {
  color: #e2b34b;
  text-align: center;
}

.event-card-links {
  display: flex;
  justify-content: center;
}
.card-footer {
  background-color: rgba(0, 0, 0, 0.8);
  border-top: none;
}
.card-header {
  background-color: rgba(0, 0, 0, 0.8);
  border-bottom: none;
}

/* BASEMENT CARD */

/* NAVBAR */

.bannerlogo {
  align-items: center;
  justify-content: center;
}
#top-navbar {
  /* margin-bottom: 250px; */
  /* padding: 0px 0px 250px 0px; */
}
.navbar {
  /* margin-bottom: 250px; */
  position: relative;
  width: 100%;
  right: auto;
  left: auto;
  z-index: 1030;
}

/* MAINVIEW */
.logoback {
  opacity: 0.7;
}

.event-one {
  color: white;
}

/* SOCIALSVIEW */

.socials {
  /* position: fixed; */
  bottom: 0;
  padding: 0px 0px 0px 0px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.socials-text {
  color: white;
}

.dale {
  border-top: 2px solid #e2b34b;
  font-size: small;
}
